import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import ReCAPTCHA, {ReCAPTCHA as ReCAPTCHA2} from 'react-google-recaptcha';

import {siteKey} from 'env/env.json';
import {errorTimer} from 'utils/errorTimer';
import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {StorageItemsNames} from 'enums';

import {getCode, getPath} from 'store/components/AuthSlice';
import {hideAllExcept, hidePopup, showPopup} from 'store/components/PopupSlice';

import Input from 'components/input/Input';
import Button from 'components/button/Button';
import LinkContainer from './assets/components/LinkContainer';
import RulesCheckbox from 'components/rulesCheckbox/RulesCheckbox';

import './PhoneForm.scss';

interface IPhoneForm {
  phone: string;
  setPhone: any;
  cardNumber: string;
  setCardNumber: any;
  setShowCodeForm: any;
  showPhoneInput: boolean;
  rememberCardNumber: boolean;
  setShowPhoneInput: any;
  setRememberCardNumber: any;
  setAuthTitle?: React.Dispatch<React.SetStateAction<string>>,
  setAuthSubTitle?: React.Dispatch<React.SetStateAction<string>>,
  outsideDisabler?: boolean;
}

const captchaToken = '03AFcWeA5Sq-T3qU0hoVzyyTNnShd8AtFM22U-wzDJPxYCVbJr_f41i3IK75ZFPUWJX9KmGQmzwN2qtvJiKgC6KeGN5MEwElAOlfpdlfg7Hs1nnN7Q_YOt3M4q--4EBXegLxQGGEOSx76kTqEJx0euJwEny4e229VnGAr78ilhYXyVZXhh28p6enMRW-mZZaNwCMyHJ2VJyBqFOiMGa61dhPAG0qREQKInq5S6zCJrEHsljkJkdsk9mJXI-4eq9YWuvrpPShPWbQ8tLZ-gx33keeONTjbO1sjAYUk-Z9_aTbANeUKHTpE1Zn5dpGzfo3MivlbyNHvCHpqqfNlCcfbq6lF1x9IgfeGc-_UD_ezwoUUHd9vDMftP3zSheDAmjeAzWlhmyUvwY-cNa8eECTfWw1ENZ7wPn195Wl4g-9y8pqqn_MbDyZlLPZna06KRc6gItqUtfLbDRLwfMpe7Jck-xIpn8I4fs5ymGyVVp4jHHP-K7WlMToTDI2ncE4JdTwn7Jdz2uXj3IEZNriN2hUcAJnOaXVPFHu97IvKIHD2EPz9vGUL6YX1PQbsoxX28KF16h1zDI06Tibsm65M4rFqt6MNOvk17r96uohet8nVBcMRWD8L6DkN9BLCghONp9ZahjiDaSJvrPRkrMZbmkS-kigTqC3--iuxia6oLGvEnNftzZSM51nqE8CoCAfdmi6wXsM_iUG6ySATUzJS3GqBqbhBl4My5pGTFDkiCEYlAqWxQPjwPVI06gJuD4puyPJxBGYQKQ77tkW02Yugr8FcbyIjQlvQJl_75WQtabqM5Tn-ZcvEi6V_nI1LpkYDfWC3wTGq5KJ8f9Hn2ESOsaECgxBRfLtYUT_f8TtiVYmmZPBfO1gMB4fcsyK-yxA4PM_G1rPcHe7KOTUEFqQ5r0EzG-z5Sqf2jf6yw7FvQoW5PtKrEoT5aMsLXsxTVpMB__kRW7pSQgomjIWDxgUh-ygUvYtvSw-NvZWMEY03sUkfW75IzvLwwxcZy5y9LQUpm5HYE9BnN4HQRKYWTfjy2lJKbit75mC0IPm0ZjRodfVNaPSaTptie9sbTkOjaeKAkTa6DnAxNPCuxSC4-05N5FknYnpzSR64nILghjV0Axy-pDPb5tjjm3C6SUCNn91LY504UPq1cAKodwmG7AiIeBGRwgYCRpIOOJwxjABBC9WAHK_KnWQDdN5n7DBBfupTU26d2_epsJUqUvK-128X1pMJ6rasP6I-oGMoVa2DgGD7DAP4Co4BYpk-4J5w5p6wYizVejiBoRg5ybc_3c-6LNA8b5CXCoMXBQboQ3TzTFlM74FqfZ0sBVwcSkid03Qcb8UAxb3eUTR9VDItYYmzl7qP-bTIsFW480B9-MKFJbgiKD0VJWhMgHLZdwP1qQnKXV3ODw-U8cV4cFFN0byuPCutnvr1HhrJStQrpw2km4rjIJWv89orlFyqtkTNk7J7kP2VSHPKJRRq8rSvxuTNMVnUzZgIj1B2xFF-V5KnE92vAX7FF25cvzB6pLtLidlnnehWKOFaOpB-zLiJKmn63c1DvoEh3pQGSRWC8K44QUFL6dkJ81drrAPr5PF3nry97lEBU8Jo7oEjaKtfdhSpG7hw8RwcVCGhn6BYIjn9qShiwbn5R8bIB1LNOVpzdqY7uSNTPOQ-_Mz9lBQmrMBDgbJBcSpEon5Ev-a_qeo74A1abwCD6HQTwKPjOwcqgV9NDdcW7cBlaYS_eha7RVvXnOnVD34muMG4TBvOYZEbLuT6RN53fmjkPy_u_K7BJhreXeR1UEAhpWh30dK2a';

const PhoneForm: React.FC<IPhoneForm> = ({
  phone,
  setPhone,
  setShowCodeForm,
  cardNumber,
  setCardNumber,
  showPhoneInput,
  setShowPhoneInput,
  setRememberCardNumber,
  rememberCardNumber,
  setAuthTitle,
  setAuthSubTitle,
  outsideDisabler
}) => {
  const recaptchaRef = useRef<ReCAPTCHA2>(null);
  const href = window.location.href;
  const domainNameMTS = 'mtsfirst';
  const domainNameMakfa = 'makfa';
  const domainNameNLoto = 'loto';
  const domainNameSmeg = 'smeg';
  const domainNameProektirovanie = 'proektirovanie';
  const domainNameGeo = 'geo';
  const domainNameRuEx = 'r-express';
  const domainNameMTSMission1 = 'mts.';
  const domainNameMTSMission2 = 'mts-';
  const domainNameLocal = 'local';
  const domainNameLeaderABC = 'lider_abc';
  const domainNameBirthday = 'birthday';
  const domainNameRaif = 'reif';
  const domainNameBrucite = 'brucite';
  const domainNameMascoglass = 'mascoglass';

  const dispatch = useAppDispatch();
  const {search} = useLocation();

  const visiblePopup = useAppSelector((state) => state.popup.popup);
  const currentDomainName = useAppSelector((state) => state.settings.currentDomainName);
  const allowAuth = useAppSelector((state) => state.settings.allowAuth);
  const test = localStorage.getItem('tester');

  const [captcha, setCaptcha] = useState<string>('');
  const [captchaError, setCaptchaError] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState('Авторизация');
  const [description, setDescription] = useState('Введите номер Универсального сертификата для авторизации');
  const [disableButtonPhoneCatch, setDisableButtonPhoneCatch] = useState(false);

  const disabledButtonPhone = phone.includes('_') || !phone || captcha === '';
  const disabledButtonCardCode = !cardNumber || cardNumber.includes('_');
  const showCheckBox = (
    href.includes(domainNameMTS)
    || href.includes(domainNameMakfa)
    || href.includes(domainNameNLoto)
    || href.includes(domainNameSmeg)
    || href.includes(domainNameBrucite)
    || href.includes(domainNameProektirovanie)
    || href.includes(domainNameLocal)
    || href.includes(domainNameLeaderABC)
    || href.includes(domainNameBirthday)
    || href.includes(domainNameGeo)
    || href.includes(domainNameRuEx)
    || href.includes(domainNameMTSMission1)
    || href.includes(domainNameMTSMission2)
    || href.includes(domainNameMascoglass)
  ) && !showPhoneInput;

  useEffect(() => {
    if (rememberCardNumber) {
      setTitle('Восстановление номера сертификата');
      setDescription('Введите номер телефона для\nпроверки его в базе');
      return;
    } else if (showPhoneInput) {
      setDescription('Войдите или зарегистрируйтесь по номеру телефона');
    } else if (domainNameMascoglass) {
      setDescription('Введите номер Универсальной карты для авторизации');
    } else {
      setDescription('Введите номер Универсального сертификата для авторизации');
    }
    setTitle('Авторизация');
  }, [visiblePopup['auth'], showPhoneInput]);

  const mayRedirect = () => {
    setRememberCardNumber(false);
    if (!allowAuth && (test !== 'true')) {
      dispatch(hideAllExcept('dontAllowAuth'));
      return;
    }
    setLoading(true);
    dispatch(getPath({cardNumber: +cardNumber}))
      .then(({payload: {data: {data}}}: any) => {
        if (data.code === 'error') {
          setTimeout(() => {
            setError(data.result);
            setTimeout(() => setError(''), 3000);
            setLoading(false);
          }, 500);
        } else {
          localStorage.setItem(StorageItemsNames.CARD_NUMBER, cardNumber);
          if (data.result.url){
            const location = window.location.origin;
            const regex = /\/$/;
            const modUrl = data.result.url.replace(regex, '');
            if (location !== modUrl) {
              window.location.replace(`${data.result.url}?card=${cardNumber}`);
            }
          }
          setTimeout(() => {
            setLoading(false);
            setShowPhoneInput(true);
            setTitle('Авторизация');
          }, 1000);
        }
      });
  };

  const openSmsPopup = () => {
    setRememberCardNumber(false);
    if (!allowAuth && (test !== 'true')) {
      dispatch(hideAllExcept('dontAllowAuth'));
      return;
    }

    setLoading(true);
    if (!loading){
      dispatch(getCode({phone, captcha: captcha ? captcha : captchaToken}))
        .then(({payload}: any) => {
          recaptchaRef?.current?.reset();
          setCaptcha('');
          if (payload.code === 'error') {
            setLoading(false);
            setError(!payload.result.includes('Permission denied')
              ? payload.result
              : 'Произошла ошибка, попробуйте позже'
            );
            setDisableButtonPhoneCatch(true);

            setTimeout(() => {
              setError('');
              setDisableButtonPhoneCatch(false);
            }, 3000);

            if (Object.keys(payload.result).includes('captcha_response')) {
              errorTimer(setCaptchaError, payload.result.captcha_response[0]);
            }
          } else {
            setTimeout(() => {
              setLoading(false);
              setShowCodeForm(true);
            }, 1000);
          }
        });
    }
  };

  const changeCaptcha = (token: string | null) => {
    if (token) {
      setCaptcha(token);
    }
  };

  const onKeyPressHandler = (event: any) => {
    if (!allowAuth && (test !== 'true')) {
      dispatch(hideAllExcept('dontAllowAuth'));
      return;
    }

    if (event.keyCode === 13) {
      if (showPhoneInput) {
        !disabledButtonPhone && openSmsPopup();
        return;
      }
      !disabledButtonCardCode && mayRedirect();
    }
  };

  const ButtonDisabled = () => {
    if (showPhoneInput) {
      return disabledButtonPhone || disableButtonPhoneCatch || !!error;
    } else {
      if (
        href.includes(domainNameMakfa)
        || href.includes(domainNameMTS)
        || href.includes(domainNameMTSMission2)
        || href.includes(domainNameMTSMission1)
        || href.includes(domainNameNLoto)
        || href.includes(domainNameSmeg)
        || href.includes(domainNameBirthday)
        || href.includes(domainNameRaif)
        || href.includes(domainNameProektirovanie)
        || href.includes(domainNameBrucite)
        || href.includes(domainNameGeo)
        || href.includes(domainNameLocal)
        || href.includes(domainNameLeaderABC)
        || href.includes(domainNameMascoglass)
      ) {
        return disabledButtonCardCode || !!error || (outsideDisabler !== undefined ? outsideDisabler : !checkbox);
      } else {
        return disabledButtonCardCode || !!error;
      }
    }
  };

  useEffect(() => {
    setCaptcha('');
    if (search.includes('auth=open-auth&type=phone')) {
      dispatch(showPopup('auth'));
      setTimeout(() => { // без setTimeout не переключает на телефон
        setShowPhoneInput(true);
      });
    }
    if (!visiblePopup['auth']) {
      setShowPhoneInput(false);
    }
  }, [search, visiblePopup['auth']]);

  useEffect(() => {
    if (setAuthTitle) {
      setAuthTitle(title);
    }
    if (setAuthSubTitle) {
      setAuthSubTitle(description);
    }
  }, [title, description]);

  return (
    <div 
      className={`
        phone-form 
        ${rememberCardNumber || showPhoneInput ? 'phone-form__active' : ''}
      `} 
      onKeyUp={onKeyPressHandler}>
      <h3
        className={`
          phone-form__title 
          ${rememberCardNumber ? 'phone-form__title_large-size' : ''}
        `}>
        {title}
      </h3>
      <p className={'phone-form__description'}>
        {description}
      </p>
      {showPhoneInput ? (
        <Input
          error={error}
          setError={setError}
          placeholder={'Телефон'}
          onChange={() => setDisableButtonPhoneCatch(false)}
          type={'tel'}
          value={phone}
          setValue={setPhone}
          marginBottom={16}
          paddingBottom={10}
        />
      ) : (
        <Input
          error={error}
          type={'tel'}
          placeholder={'Номер сертификата'}
          setError={setError}
          value={cardNumber}
          mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
          setValue={setCardNumber}
          marginBottom={16}
          paddingBottom={10}
        />
      )}
      <div 
        className={'phone-form__action-container'}>
        <div className={'phone-form__captcha-container'}>
          {showPhoneInput ? (
            <div className={'register__captcha'}>
              <ReCAPTCHA
                // @ts-ignore
                ref={recaptchaRef}
                size={'normal'}
                onChange={changeCaptcha}
                onExpired={() => setCaptcha('')}
                sitekey={siteKey}
              />
              <span
                className={`register__captcha-error ${captchaError ? 'register__captcha-error_active' : ''}`}>
                {captchaError}
              </span>
            </div>
          ) : null}
          <Button
            disabled={ButtonDisabled()}
            width={'100%'}
            loading={loading}
            marginBottom={showPhoneInput ? rememberCardNumber ? 24 : 30 : 12}
            onClick={showPhoneInput ? openSmsPopup : mayRedirect}>
            {'Войти'}
          </Button>
        </div>
        <RulesCheckbox
          showCheckBox={showCheckBox}
          checkbox={checkbox}
          setCheckbox={setCheckbox}
          currentDomainName={currentDomainName}
        />
        <LinkContainer
          showPhoneInput={showPhoneInput}
          rememberCardNumber={rememberCardNumber}
          setShowPhoneInput={setShowPhoneInput}
          setRememberCardNumber={setRememberCardNumber}
          setTitle={setTitle}
          setError={setError}
        />
      </div>
    </div>
  );
};

export default PhoneForm;
